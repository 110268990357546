import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, of, switchMap, tap, timer, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { PaymentActions } from './payment.actions';
import {
  PaymentCybersourceEventPayload,
  PaymentCybersourceMethod,
} from '@aaa/interface-joinRenew-payment-paymentCybersource';
import { PaymentCaptureContextResponseObject } from '../../modules/join-renew/hoosier/events/payment-capture-context';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { getPaymentFormValues, getPaymentInitializeStatus, getPaymentToken, getPaymentType } from './payment.selectors';
import { PaymentInitializeStatus } from './payment.model';
import { updateTreeValidity } from '../../modules/share/form.utils';
import { PaymentFormVm } from '@aaa/emember/share/payment-form';
import { CybersourcePaymentService, Shift4PaymentService } from '@aaa/emember/store-services';
import { NotificationService } from '../../modules/share/notification/notification.service';
import { ExecuteService } from '../services/execute.service';
import { RequestError, RequestErrorType } from '../generic-errors';
import { isValidationShift4Error } from './utils';
import { Validators } from '@angular/forms';
import { AutorenewConfirmationComponent } from '../../modules/share/autorenew-confirmation/autorenew-confirmation.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filterByClubIds } from '../utils/filter-by-club-ids';
import { ClubApp } from '@aaa/emember/types';

@Injectable({ providedIn: 'root' })
export class PaymentEffects {
  store = inject(Store);
  executeService = inject(ExecuteService);
  cybersourcePayment = inject(CybersourcePaymentService);
  shift4Payment = inject(Shift4PaymentService);
  actions$ = inject(Actions);
  autoRenewChangeAction$ = this.actions$.pipe(filterByClubIds(this.store, [ClubApp.SouthJersey]));
  paymentFormVm = inject(PaymentFormVm);
  notification = inject(NotificationService);
  modalService = inject(NzModalService);

  formChanged$ = createEffect(() => {
    const state: [boolean, boolean] = [false, true];

    return this.autoRenewChangeAction$.pipe(
      ofType(PaymentActions.changedAutoRenew),
      tap(({ value }) => {
        state[0] = state[1];
        state[1] = value;
      }),
      filter(() => state[1] === false),
      switchMap(() => {
        const result = this.modalService.create({
          nzContent: AutorenewConfirmationComponent,
          nzFooter: null,
          nzCloseIcon: '',
        });

        return result.afterClose.pipe(map((value) => PaymentActions.setAutoRenew({ value: !value })));
      }),
    );
  });

  initPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.initCardForm),
      switchMap(({ paymentType }) => {
        if (paymentType === 'cybersource') {
          return this.getPaymentCaptureContext().pipe(
            map((sessionToken) => PaymentActions.initCardFormSucceeded({ sessionToken })),
            catchError((error) => of(PaymentActions.initCardFormFailed({ error }))),
          );
        } else if (paymentType === 'shift4') {
          return of(PaymentActions.initCardFormSucceeded({ sessionToken: '_' }));
        } else {
          const error = new RequestError(RequestErrorType.PaymentTypeError, null);
          return of(PaymentActions.initCardFormFailed({ error }));
        }
      }),
    ),
  );

  generatePaymentToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.generateToken),
      withLatestFrom(this.store.select(getPaymentType), this.store.select(getPaymentFormValues)),
      switchMap(([, paymentType, formValues]) => {
        const { card } = formValues;
        const month = card?.month || 0;
        const year = card?.year || 0;
        // const number = card?.number || 0
        // const cvv = card?.cvv || 0

        if (paymentType === 'cybersource') {
          return this.cybersourcePayment.createToken(month, year).pipe(
            map((paymentToken) => PaymentActions.generateTokenSucceeded({ paymentToken })),
            catchError((data) => {
              const error = new RequestError(RequestErrorType.PaymentGenerateTokenError, data);
              return of(PaymentActions.generateTokenFailed({ error }));
            }),
          );
        }

        if (paymentType === 'shift4') {
          return of(true).pipe(
            switchMap((_) => of(_).pipe(withLatestFrom(this.store.select(getPaymentToken)))),
            map(([, paymentToken]) => PaymentActions.generateTokenSucceeded({ paymentToken })),
          );
        }

        const error = new RequestError(RequestErrorType.PaymentTypeError, { paymentType });
        return of(PaymentActions.generateTokenFailed({ error }));
      }),
    ),
  );

  refreshCybersourceSessionToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.initCardFormSucceeded),
      withLatestFrom(this.store.select(getPaymentType)),
      filter(([, paymentType]) => paymentType === 'cybersource'),
      switchMap(([, paymentType]) =>
        timer(environment.paymentConfig.cyberSourceSessionTimeout).pipe(
          withLatestFrom(this.store.select(getPaymentInitializeStatus)),
          filter(([, initializeStatus]) => initializeStatus === PaymentInitializeStatus.SUCCESS),
          map(() => PaymentActions.initCardForm({ paymentType })),
        ),
      ),
    ),
  );

  // Trigger to check validation
  validatePaymentStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.validate),
      switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getPaymentType)))),
      switchMap(([, paymentType]) => {
        if (paymentType === 'cybersource') {
          return of(true).pipe(
            map(() => {
              const paymentInvalid = this.paymentFormVm.formGroup.status !== 'VALID';

              if (paymentInvalid) {
                updateTreeValidity(this.paymentFormVm.formGroup);

                return PaymentActions.validateFailed();
              }

              return PaymentActions.validateSucceeded({});
            }),
          );
        }

        if (paymentType === 'shift4') {
          this.paymentFormVm.formGroup.controls.card.controls.cvv.clearValidators();
          this.paymentFormVm.formGroup.controls.card.controls.number.clearValidators();
          this.paymentFormVm.formGroup.controls.card.controls.month.clearValidators();
          this.paymentFormVm.formGroup.controls.card.controls.year.clearValidators();

          return this.shift4Payment.createToken().pipe(
            tap(() => updateTreeValidity(this.paymentFormVm.formGroup)),
            map((token) => PaymentActions.validateSucceeded({ token })),
            catchError((error) => {
              if (isValidationShift4Error(error)) {
                switch (error.code) {
                  case 'incomplete_cvc':
                    this.paymentFormVm.formGroup.controls.card.controls.cvv.addValidators(Validators.required);
                    break;
                  case 'incomplete_number':
                    this.paymentFormVm.formGroup.controls.card.controls.number.addValidators(Validators.required);
                    break;
                  case 'incomplete_expiry':
                  case 'invalid_expiry':
                    this.paymentFormVm.formGroup.controls.card.controls.month.addValidators(Validators.required);
                    this.paymentFormVm.formGroup.controls.card.controls.year.addValidators(Validators.required);
                    break;
                }

                updateTreeValidity(this.paymentFormVm.formGroup);
              }

              return of(PaymentActions.validateFailed());
            }),
          );
        }

        return of(PaymentActions.validateFailed());
      }),
    ),
  );

  // Show notification if validation failed or succeeded
  validatePaymentForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PaymentActions.validateFailed, PaymentActions.validateSucceeded),
        tap(() => this.notification.clear()),
        tap(() => {
          if (this.paymentFormVm.formGroup.controls.card.controls.month.invalid) {
            this.notification.show('Please choose your Credit Card Expiration Month');
          }
          if (this.paymentFormVm.formGroup.controls.card.controls.year.invalid) {
            this.notification.show('Please choose your Credit Card Expiration Year');
          }
          if (this.paymentFormVm.formGroup.controls.card.controls.number.invalid) {
            this.notification.show('Please enter a valid credit card number');
          }
          if (this.paymentFormVm.formGroup.controls.card.controls.cvv.invalid) {
            this.notification.show('Please enter 3-digit credit card verification code');
          }
        }),
      ),
    { dispatch: false },
  );

  getPaymentCaptureContext() {
    const payload = { method: PaymentCybersourceMethod.CAPTURE_CONTEXT };

    return this.executeService
      .paymentQuery<PaymentCaptureContextResponseObject, PaymentCybersourceEventPayload>(payload)
      .pipe(
        map((paymentObject) => {
          const paymentError = !!paymentObject?.meta.isError;

          if (paymentError) {
            throw new RequestError(RequestErrorType.PaymentCaptureContextError, paymentObject);
          }

          return paymentObject.response;
        }),
      );
  }
}
