import { MembershipCard } from '@aaa/emember/store-types';
import { ClubApp, Environment } from '@aaa/emember/types';

export const getMembershipBlankCard = (env: Environment) => {
  const blankCards: MembershipCard[] = [];
  const textColor = env.clubId === ClubApp.SouthJersey ? 'white' : 'black';

  if ([ClubApp.MidStates, ClubApp.Northampton].includes(env.clubId)) {
    blankCards.push({
      textColor,
      membershipLabel: 'Premier RV',
      filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/premier-rv-300.png`,
    });
  }

  if (
    [ClubApp.MidStates, ClubApp.Hoosier, ClubApp.Northampton, ClubApp.Northway, ClubApp.SouthJersey].includes(
      env.clubId,
    )
  ) {
    blankCards.push({
      textColor,
      membershipLabel: 'Premier',
      filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/premier-300.png`,
    });
  }

  if ([ClubApp.MidStates, ClubApp.Hoosier, ClubApp.Northampton, ClubApp.Northway].includes(env.clubId)) {
    blankCards.push({
      textColor,
      membershipLabel: 'Plus RV',
      filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/plus-rv-300.png`,
    });
  }

  blankCards.push({
    textColor,
    membershipLabel: 'Plus',
    filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/plus-300.png`,
  });

  blankCards.push({
    textColor,
    membershipLabel: [ClubApp.Hoosier].includes(env.clubId) ? 'Classic' : 'Basic',
    filePath: `${env.cloudStorageURL}/${env.clubId}/emember/revision/basic-300.png`,
  });

  return blankCards;
};

export const getPreviewMembershipCard = (env: Environment): { [key: string]: string } => {
  const BASIC = `${env.cloudStorageURL}/${env.clubId}/emember/revision/preview-basic.png`;
  const PLUS = `${env.cloudStorageURL}/${env.clubId}/emember/revision/preview-plus.png`;
  const PREMIER = `${env.cloudStorageURL}/${env.clubId}/emember/revision/preview-premier.png`;

  return { CLASSIC: BASIC, BASIC, PLUS, PREMIER };
};

export const getPreviewMembershipOnSaleCard = (clubId: ClubApp): { [key: string]: string } => {
  let BASIC = '',
    PLUS = '',
    PREMIER = '';

  switch (clubId) {
    case ClubApp.Hoosier:
      {
        PLUS = 'MOST POPULAR';
      }
      break;
    case ClubApp.SouthJersey:
      {
        BASIC = 'On sale $50';
        PLUS = 'On sale $140';
        PREMIER = 'On sale $200';
      }
      break;
  }

  return { CLASSIC: BASIC, BASIC, PLUS, PREMIER };
};
