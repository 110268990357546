import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { Flex, FlexMicroform } from '../../modules/join-renew/hoosier/services/cybersource';
import { supportedCardTypes } from '../../modules/share/form/validators/validator.service';
import { WindowRefService } from '../../modules/share/services/window-ref.service';
import { twoDigitMonth } from '../../modules/share/utils/two-digit-month';

export interface FlexFormEvents {
  event: 'numberLoaded' | 'numberChanged' | 'numberBlur' | 'cvvLoaded' | 'cvvChanged' | 'cvvBlur' | 'initialized';
  errors: { [key: string]: boolean } | null;
  options?: {
    cardType: string;
    cardName: string;
  };
}

@Injectable({ providedIn: 'root' })
export class CybersourcePaymentService {
  flexMicroForm: FlexMicroform | null = null;
  document = inject(DOCUMENT);
  windowRef = inject(WindowRefService);
  flexForm$: Observable<FlexFormEvents> | null = null;
  init(paymentCardId: string, paymentCvvId: string, token: string) {
    if (!this.flexForm$) {
      this.flexForm$ = this.initFlexForm(paymentCardId, paymentCvvId, token);
    }

    return this.flexForm$;
  }

  createToken(month: number, year: number) {
    return this.generateTokenFlexForm(month, year);
  }

  generateTokenFlexForm(month: number, year: number): Observable<string> {
    return new Observable((observe) => {
      const options = {
        expirationMonth: twoDigitMonth(month),
        expirationYear: String(year),
      };

      this.flexMicroForm?.createToken(options, (error, token) => {
        if (error) {
          observe.error('The flex form invalid');
        }
        if (!error && token) {
          observe.next(token);
        }
        observe.complete();
      });
    });
  }

  initFlexForm(paymentCardId: string, paymentCvvId: string, token: string): Observable<FlexFormEvents> {
    return new Observable((observe) => {
      if (this.flexMicroForm) {
        observe.next({
          event: 'initialized',
          errors: null,
        });
      }
      try {
        this.flexMicroForm = (new this.windowRef.nativeWindow.Flex(token) as Flex).microform({
          styles: {
            input: {
              'font-size': '16px',
              'font-weight': '200',
              'font-family': 'proxima_nova, sans-serif',
              color: '#313131',
            },
            '::placeholder': {
              color: '#595959',
              opacity: '0.7',
            },
          },
        });
      } catch (err) {
        observe.error('Invalid initialization microform');

        return;
      }

      const cardNumberRef = this.document.querySelector(paymentCardId);
      const cvvFRef = this.document.querySelector(paymentCvvId);

      if (cardNumberRef && cvvFRef) {
        const cardNumberField = this.flexMicroForm.createField('number', {
          placeholder: 'Enter card number',
        });

        cardNumberField.on('blur', () => {
          observe.next({ event: 'numberBlur', errors: null, options: { cardType: '', cardName: '' } });
        });
        cardNumberField.on('load', () => {
          const errors = { required: true };
          observe.next({ event: 'numberLoaded', errors, options: { cardType: '', cardName: '' } });
        });
        cardNumberField.on('change', (data) => {
          const dataCard = data.card.length ? data.card[0] : null;
          let errors = null;
          const cardType = dataCard?.name || '';
          const cardName = dataCard?.brandedName || '';

          if (!supportedCardTypes.includes(cardType)) {
            errors = { ...(errors ? errors : {}), unsupportedCard: true };
          }

          if (!data.valid) {
            errors = { ...(errors ? errors : {}), invalid: true };
          }

          observe.next({ event: 'numberChanged', errors, options: { cardType, cardName } });
        });

        const cvvField = this.flexMicroForm.createField('securityCode', {
          placeholder: 'CVV',
        });

        cvvField.on('blur', () => {
          observe.next({ event: 'cvvBlur', errors: null, options: { cardType: '', cardName: '' } });
        });
        cvvField.on('change', (data) => {
          const errors = data.valid ? null : { invalid: true };
          observe.next({ event: 'cvvChanged', errors });
        });
        cvvField.on('load', () => {
          const errors = { required: true };
          observe.next({ event: 'cvvLoaded', errors });
        });

        cardNumberField.load(cardNumberRef as any);
        cvvField.load(cvvFRef as any);

        observe.next({
          event: 'initialized',
          errors: null,
        });
      } else {
        observe.error('Invalid paymentCardId or paymentCvvId');
      }
    });
  }
}
